import React, {
  useState,
  useRef,
  useMemo,
  memo,
  useEffect,
  useContext
} from 'react';
import { Row, Col } from 'antd';
import { pdfjs, Document, Page } from 'react-pdf';
import { useDispatch } from 'react-redux';
import VirtualList, { ListRef } from 'rc-virtual-list';
import { useTypedSelector } from '@hooks';
import {
  selectComment,
  setActivePageNumber,
  setTurnoverDegree,
  setZoomFactorAction
} from '@redux/actions/mediaViewerAction';
import AssetInfo from '@pages/MediaViewer/AssetViewer/AssetInfo';
import { ReactComponent as PagesSvg } from '@assets/icons/MediaViewer/pages.svg';
import { ReactComponent as ArrowRightSvg } from '@assets/icons/arrow-right-without-line.svg';
import { ReactComponent as ArrowLeftSvg } from '@assets/icons/arrow-left-without-line.svg';
import './PdfCard.less';
import PanelAction from '@pages/MediaViewer/AssetViewer/PanelAction';
import PlaybackPreparingLoader from '@pages/MediaViewer/AssetViewer/PlaybackPreparingLoader';
import { AssetVersionItemDto, DocumentMetadataDto } from '@api/Api';
import classNames from 'classnames';
import { FabricContext } from '@context/FabricContext';
import FabricCanvas from '@pages/MediaViewer/AssetViewer/Canvas/Canvas';

if (typeof (Promise as any).withResolvers === 'undefined') {
  (Promise as any).withResolvers = () => {
    let resolve;
    let reject;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
    return { promise, resolve, reject };
  };
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

// https://github.com/mozilla/pdf.js/blob/3b615e4ca369ff31e6399dea80383ffeffe96240/web/viewer_compatibility.js#L42-L48
const maxCanvasSize = 2048;

interface IPdfCardProps {
  isExpand: boolean;
  setIsExpand(val: boolean): void;
  onReady: (a?: any) => any;
  asset: AssetVersionItemDto;
  isPreparing: boolean;
}

let IS_MOUSE_DOWN = false;
let OFFSET = [0, 0];

export default memo(function PdfCard({
  isExpand,
  setIsExpand,
  onReady,
  asset,
  isPreparing
}: IPdfCardProps) {
  const fabricContext = useContext(FabricContext);
  const dispatch = useDispatch();
  const listRef = useRef<ListRef>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const documentRef = useRef<HTMLDivElement>(null);
  const canvasContainerRef = useRef<HTMLImageElement>(null);

  const fileUrl = useMemo(() => {
    if (isPreparing) return null;
    const { proxy } = asset.metadata as DocumentMetadataDto;
    return proxy.url || '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset.id, isPreparing]);

  const [pagesCount, setPagesCount] = useState(1);

  const isToolsExpanded = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isToolsExpanded
  );
  const isSelectedCanvasObject = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isSelectedCanvasObject
  );
  const activePageNumber = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.activePageNumber
  );

  const zoomFactor = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.zoomFactor
  );
  const turnoverDegree = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.turnoverDegree
  );

  const [isShowPages, setIsShowPages] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<{ width: number; height: number }>({
    width: 1605,
    height: 2272
  });

  const pagesArray = useMemo(
    () =>
      new Array(pagesCount).fill('').map((_, index) => ({ page: index + 1 })),
    [pagesCount]
  );

  const onMouseDown = (e: any) => {
    if (!documentRef.current) return;
    IS_MOUSE_DOWN = true;
    OFFSET = [
      documentRef.current.offsetLeft -
        (e.clientX || e.touches?.[0]?.clientX || 0),
      documentRef.current.offsetTop -
        (e.clientY || e.touches?.[0]?.clientY || 0)
    ];
  };
  const onMouseUp = () => {
    IS_MOUSE_DOWN = false;
  };
  const onMouseLeave = () => {
    IS_MOUSE_DOWN = false;
  };
  const onMouseMove = (e: any) => {
    if (isToolsExpanded || isSelectedCanvasObject) return;
    if (!documentRef.current || !canvasContainerRef.current) return;
    if (!IS_MOUSE_DOWN) return;

    const mousePosition = {
      x: e.clientX || e.touches?.[0]?.clientX,
      y: e.clientY || e.touches?.[0]?.clientY
    };
    documentRef.current.style.left = `${mousePosition.x + OFFSET[0]}px`;
    documentRef.current.style.top = `${mousePosition.y + OFFSET[1]}px`;
    canvasContainerRef.current.style.left = `${mousePosition.x + OFFSET[0]}px`;
    canvasContainerRef.current.style.top = `${mousePosition.y + OFFSET[1]}px`;
  };
  useEffect(() => {
    const container = wrapperRef?.current;
    const document = documentRef?.current;
    const canvasContainer = canvasContainerRef?.current;
    if (!container || !document || !canvasContainer) return;
    const { height, width } = pageSize;
    const containerHeight = container.offsetHeight;
    const containerWidth = container.offsetWidth;
    if (height > containerHeight || width > containerWidth) {
      const scaleHeight = containerHeight / (height / 100);
      const scaleWidth = containerWidth / (width / 100);
      const zoomFactor = scaleHeight > scaleWidth ? scaleWidth : scaleHeight;

      dispatch(setZoomFactorAction(zoomFactor / 100));
    }
  }, [dispatch, pageSize]);

  useEffect(() => {
    if (listRef && listRef.current) {
      listRef.current.scrollTo({
        index: activePageNumber - 1
      });
    }
  }, [isShowPages, activePageNumber, listRef]);

  useEffect(() => {
    const document = documentRef?.current;
    const container = canvasContainerRef?.current;
    if (!document || !container || !fabricContext.canvas) return;
    const rect = document.getBoundingClientRect();
    container.style.width = `${rect.width}px`;
    container.style.height = `${rect.height}px`;
    fabricContext.canvas.setWidth(rect.width);
    fabricContext.canvas.setHeight(rect.height);
    fabricContext.canvas.setViewportTransform([
      zoomFactor * 10,
      0,
      0,
      zoomFactor * 10,
      0,
      0
    ]);
    canvasContainerRef.current.style.translate = `-${Math.round(
      rect.width / 2
    )}px -${Math.round(rect.height / 2)}px`;
  }, [pageSize, zoomFactor, fabricContext.canvas, turnoverDegree]);

  const onChangaPage = (page: number) => {
    onResetPosition();
    dispatch(setActivePageNumber(page));
    dispatch(selectComment(null));
  };

  const onResetPosition = () => {
    if (!documentRef.current || !canvasContainerRef.current) return;
    documentRef.current.style.left = '50%';
    documentRef.current.style.top = '50%';
    canvasContainerRef.current.style.left = '50%';
    canvasContainerRef.current.style.top = '50%';
  };

  if (isPreparing) return <PlaybackPreparingLoader />;

  return (
    <Document
      className="media_viewer_pdf"
      file={fileUrl}
      onLoadSuccess={(pdf) => {
        setPagesCount(pdf.numPages);
        onReady();
      }}
      loading={<PlaybackPreparingLoader />}
    >
      {isShowPages && (
        <div className="media_viewer_pdf-sidebar">
          <VirtualList
            className="media_viewer_pdf-list"
            data={pagesArray}
            height={wrapperRef?.current?.offsetHeight || 500}
            fullHeight={true}
            itemKey="page"
            ref={listRef}
          >
            {(item) => {
              return (
                <PageThumbnail
                  page={item.page}
                  active={item.page === activePageNumber}
                  maxWidth={102}
                  maxHeight={147}
                  onClick={onChangaPage}
                />
              );
            }}
          </VirtualList>
        </div>
      )}
      <div className="media_viewer_pdf-wrapper" ref={wrapperRef}>
        <div
          className="media_viewer_pdf-inner"
          ref={documentRef}
          style={{
            transform: `translateX(-50%) translateY(-50%)  rotate(${turnoverDegree}deg) scale(${zoomFactor})`,
            ...pageSize
          }}
        >
          <Page
            devicePixelRatio={1}
            width={
              pageSize.width > pageSize.height ? pageSize.width : undefined
            }
            height={
              pageSize.height > pageSize.width ? pageSize.height : undefined
            }
            scale={1}
            pageNumber={activePageNumber}
            className="media_viewer_pdf-page"
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onLoadSuccess={(pdfPage) => {
              let { originalWidth, originalHeight } = pdfPage;
              if (pdfPage.rotate % 180 !== 0) {
                const tmp = originalWidth;
                originalWidth = originalHeight;
                originalHeight = tmp;
              }
              const scale = Math.min(
                maxCanvasSize / originalWidth,
                maxCanvasSize / originalHeight
              );
              setPageSize({
                width: originalWidth * scale,
                height: originalHeight * scale
              });
            }}
            loading={<PlaybackPreparingLoader />}
          />
        </div>
        <div
          onMouseDown={onMouseDown}
          onTouchStart={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onTouchMove={onMouseMove}
          onMouseLeave={onMouseLeave}
          onTouchEnd={onMouseLeave}
          ref={canvasContainerRef}
          className="media_viewer_pdf-canvas"
        >
          <FabricCanvas />
        </div>

        <PanelAction
          zoom={zoomFactor}
          setZoom={(v) => {
            const document = documentRef?.current;
            const container = canvasContainerRef?.current;
            if (!document || !container) return;

            const rect = document.getBoundingClientRect();
            container.style.width = `${rect.width}px`;
            container.style.height = `${rect.height}px`;
            dispatch(setZoomFactorAction(v));
          }}
          isExpand={isExpand}
          setIsExpand={(v) => {
            onResetPosition();
            setIsExpand(v);
          }}
          setRotate={(v) => {
            const rt = turnoverDegree + v === 360 ? 0 : turnoverDegree + v;
            dispatch(setTurnoverDegree(rt));
          }}
        />
        <Row
          className="media_viewer_pdf-action"
          gutter={8}
          align="stretch"
          wrap={false}
        >
          <Col>
            <Row className="media_viewer_pdf-action-pages">
              <Col>
                <button
                  className="media_viewer_pdf-btn"
                  type="button"
                  disabled={activePageNumber === 1}
                  onClick={() => onChangaPage(activePageNumber - 1)}
                >
                  <ArrowLeftSvg />
                </button>
              </Col>
              <Col>
                {activePageNumber} of {pagesCount}
              </Col>
              <Col>
                <button
                  disabled={activePageNumber === pagesCount}
                  className="media_viewer_pdf-btn"
                  type="button"
                  onClick={() => onChangaPage(activePageNumber + 1)}
                >
                  <ArrowRightSvg />
                </button>
              </Col>
            </Row>
          </Col>
          <Col>
            <button
              className="media_viewer_pdf-btn media_viewer_pdf-action-btn"
              type="button"
              onClick={() => setIsShowPages(!isShowPages)}
            >
              <PagesSvg />
            </button>
          </Col>
        </Row>
      </div>
      {isExpand && (
        <div className="media_viewer_information">
          <AssetInfo />
        </div>
      )}
    </Document>
  );
});

interface PageThumbnailProps {
  maxWidth: number;
  maxHeight: number;
  page: number;
  active: boolean;
  onClick: (page: number) => void;
}

function PageThumbnail({
  maxWidth,
  maxHeight,
  page,
  active,
  onClick
}: PageThumbnailProps) {
  const [pageSize, setPageSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0
  });
  return (
    <div className="media_viewer_pdf-list-block">
      <div
        className={classNames({
          'media_viewer_pdf-list-item': true,
          active
        })}
      >
        <Page
          width={pageSize.width > pageSize.height ? pageSize.width : undefined}
          height={
            pageSize.height > pageSize.width ? pageSize.height : undefined
          }
          pageNumber={page}
          className="media_viewer_pdf-list-page"
          renderAnnotationLayer={false}
          renderTextLayer={false}
          onLoadSuccess={(pdfPage) => {
            let { originalWidth, originalHeight } = pdfPage;
            if (pdfPage.rotate % 180 !== 0) {
              const tmp = originalWidth;
              originalWidth = originalHeight;
              originalHeight = tmp;
            }
            const scaleX = maxWidth / originalWidth;
            const scaleY = maxHeight / originalHeight;
            const scale = Math.min(scaleX, scaleY);
            setPageSize({
              width: originalWidth * scale,
              height: originalHeight * scale
            });
          }}
          loading={
            <div style={{ height: '147px' }}>
              <PlaybackPreparingLoader />
            </div>
          }
        />
        <div
          className="media_viewer_pdf-list-link"
          onClick={() => onClick(page)}
        />
        <div className="media_viewer_pdf-list-number">{page}</div>
      </div>
    </div>
  );
}
